import { Resource as ResourceType } from '../types';

export const recommendation: ResourceType = {
  name: 'recommendations',
  label: 'resource.recommendations.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.recommendations.field.id.label',
      type: 'text',
    },
    {
      key: 'company_id',
      label: 'resource.recommendations.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'comment',
      label: 'resource.recommendations.field.comment.label',
      type: 'text',
      multiline: true,
    },
    {
      key: 'status',
      label: 'resource.recommendations.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'published',
          name: 'resource.recommendations.field.status.choices.published.label',
        },
        {
          id: 'requested',
          name: 'resource.recommendations.field.status.choices.requested.label',
        },
        {
          id: 'draft',
          name: 'resource.recommendations.field.status.choices.draft.label',
        },
        {
          id: 'archived',
          name: 'resource.recommendations.field.status.choices.archived.label',
        },
      ],
    },
    {
      key: 'updated_at',
      label: 'resource.recommendations.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'created_at',
      label: 'resource.recommendations.field.created_at.label',
      type: 'date',
    },
    {
      key: 'archived_at',
      label: 'resource.recommendations.field.archived_at.label',
      type: 'date',
    },
    {
      key: 'leader_id',
      label: 'resource.candidacies.field.owner_id.label',
      type: 'reference',
      reference: 'users',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      hasCreateButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
        {
          type: 'date',
          label: 'resource.recommendations.field.updated_at_gte.label',
          name: 'updated_at_gte',
        },
        {
          type: 'date',
          label: 'resource.recommendations.field.updated_at_lte.label',
          name: 'updated_at_lte',
        },
      ],
      fields: [
        {
          source: 'leader_id',
          child: {
            source: 'users.company_name',
          },
        },
        {
          source: 'comment',
        },
        {
          source: 'status',
        },
        {
          source: 'updated_at',
        },
      ],
    },
    edit: {
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.recommendations.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.recommendations.section.profile.title',
                layout: [['comment'], ['archived_at'], ['status']],
                inputs: {
                  comment: {
                    validators: [
                      { key: 'maxLength', value: 10_000 },
                      { key: 'required' },
                    ],
                  },
                  archived_at: {
                    disabled: true,
                    rules: {
                      hide: [
                        {
                          property: 'archived_at',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                  status: {
                    validators: [{ key: 'required' }],
                  },
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.recommendations.section.details.title',
              layout: [
                ['id'],
                ['created_at', 'updated_at'],
                ['leader_id'],
                ['company_name'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
              },
              fields: {
                leader_id: {
                  addLabel: true,
                  label: 'resource.proposals.field.candidacy_owner_name.label',
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                company_name: {
                  source: 'leader_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.users.field.company_name_profile.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
              },
            },
          ],
        },
      ],
    },
    create: {
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.recommendations.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.recommendations.section.profile.title',
                layout: [['company_id'], ['comment'], ['status']],
                inputs: {
                  company_id: {
                    child: {
                      type: 'autocomplete',
                      // Options to pass to ReferenceInput
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                    validators: [{ key: 'required' }],
                  },
                  comment: {
                    validators: [
                      { key: 'maxLength', value: 500 },
                      { key: 'required' },
                    ],
                  },
                  status: {
                    validators: [{ key: 'required' }],
                  },
                },
              },
            ],
          },
        ],
      },
    },
  },
};
